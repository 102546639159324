.invitebanner{
  background: #ebebeb;
  padding: 30px 25px 30px 15px;
  border-radius: 5px;
  .invite-title{
    font-size: 24px;
    font-weight: bold;
  }

  .btn{
    font-size: 13px;
    letter-spacing: 0.2px;
    // padding: 10px 35px 10px 35px !important;
    color: white;
    border-radius: 5px;
    border: 1px solid #69992c;
    box-shadow: inset 0 1px 1px white;
    text-shadow: 0px -1px 3px #0e4562;
    background-color: #81be32;
    background: -webkit-linear-gradient(#81be32, #76ae2e);
    background: -o-linear-gradient(#81be32, #76ae2e);
    background: -moz-linear-gradient(#81be32, #76ae2e);
    background: linear-gradient(#81be32, #76ae2e);
    text-transform: uppercase;
    &:hover{
      color: white;
      text-decoration: none;
      background: -webkit-linear-gradient(#81be32, #8fc34c);
      background: -o-linear-gradient(#81be32, #8fc34c);
      background: -moz-linear-gradient(#81be32, #8fc34c);
      background: linear-gradient(#81be32, #8fc34c);
    }
  }
}