.text-icon-bg{
  background: #fff;
}


a.text-icon-link{
  text-decoration: none !important;

  &:hover{
    .text-icon{
    background: @brand-color3;
      h3, p, i{
       // color: #fff;
      }
    }
  }
}
.text-icon {
  display:block;
  position:relative;
  overflow: visible;
  //margin-bottom: 60px;
  background: #fff;
  padding: 35px 15px 20px 15px;
  transition: all 0.5s ease;

  .text-icon-icon {
    text-align: center;
    display: block;
    position:relative;
    border-radius: 100%;
    border: 2px solid @brand-color2;
    height: 120px;
    width: 120px;
    margin: 0 auto;
    .flaticon:before, .flaticon:after{
      font-size: 70px;
    }
    i{
      font-size: 50px !important;
      color: @brand-color2;
      text-align: center;
      line-height: 100px;
    }
  }

  h3{
    margin: 20px 0 22px;
    font-size: 20px;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
  }


  .text-icon-body{
  //  overflow:hidden;
    min-height: 100px;
    text-align: center;

    p{
    font-size: 13px;
    }
  }
}

.divider-third .text-icon h3{
  color: #fff;
}