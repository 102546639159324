
.before-footer{
  display:block;
  height: 50px;

}

.footer {
  border-top:1px solid #5D6367;
  background-color: @site-footer-bg;
  display: table;
  height: 1px;
  width: 100%;
  color: #a8a6b7;
//  padding-top:150px;
  .footer-top{
    margin-top:20px;
  }
//  border-top: 20px solid @brand-color;

  h2,h3,h4{
    color: #fff;
    font-size:22px;
  }

  a{
    color: #fff;
  }
}

.footer-bottom {
//  color: #fff;
  margin-top: 25px;
  padding:15px 0;
  background: #373550;
  font-size: 13px;

}
.footer-simple .footer-bottom {
  color: #fff; }
.footer-bottom a {
  //color: #fff;
}


.footer-bottom-left {
  float: left; }
@media (max-width: 767px) {
  .footer-bottom-left {
    float: none; } }

.footer-bottom-right {
  float: right; }
@media (max-width: 767px) {
  .footer-bottom-right {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    float: none;
    margin-top: 30px;
    padding-top: 30px; }
  .footer-simple .footer-bottom-right {
    border-top-color: #F5F5F5; } }

.footer-bar {
  background-color: #1976D2;
  color: #fff;
  padding-bottom: 7px;
  padding-top: 7px; }
.footer-bar .scroll-top {
  color: #fff;
  cursor: pointer;
  font-size: 40px; }
