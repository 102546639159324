.half-splash{
  .search-form-wrapper{
    margin: 150px auto 150px;
    width: 80%;
    padding: 30px;

    .search-inner{
      background: rgba(0,0,0,.4);
      border: none;
    }
  }
}
.search-form-wrapper{

  //background:rgba(255,255,255,.6);
  //background:rgba(0,0,0,.3);
  //background: #fff;
  //background: #fff;
//  padding: 15px 15px 0 15px;

  .btn-primary{
    background: @brand-color;
    border:none;

    &:active{
      background: darken(@brand-color, 10%);
    }
  }
}

.search-form-vertical{
  .btn-primary{
    background: @brand-color;
    border:none;

    &:active{
      background: darken(@brand-color, 10%);
    }
  }
}


@media(max-width: @screen-sm) {
  .half-splash {
    .search-form-wrapper {
      margin: 15px auto 15px;
     width: 100%;
      padding: 20px;
    }
  }
}