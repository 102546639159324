.divider-box{
  padding: 30px 0 30px;
}

.divider-simple{
  padding: 30px 0 30px;
}

.divider-grey{
  padding: 50px 0px 50px 0px;
  background-color: #f5f5f5;
  border: 1px solid #dedede;
  box-shadow: inset 0 1px 1px white;
}

.divider-grey0{
  background: #EDF1F2;
  padding:0;
}

.divider-image1{
  background-image: url('../images/image1-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.divider-image2{
  background-image: url('../images/subheader-page-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  h1,h2,h3,p{
    color: #fff;
  }
}

.divider-darkgrey{
  background: #6E7C85;
}

.divider-footergrey{
  background-color: @site-footer-bg;
  color: #fff;
  h1,h2,h3,h4,a{color: #fff}
}


.divider-testimonials{
  padding: 20px 0;
  background-image: url('../images/blackfamily.jpg');
  background-position: 50% 50%;
  background-size: cover;
  margin:0;

  h1,h2,h3,p{
    color: #fff;
  }

}

.divider-black{
  padding: 20px 0;
  background: #1C1E1E;
  h1,h2,h3, a{
    color:#fff;
  }
}

.divider-firma{
  padding:0;
  .row,.col-sm-6{
    padding:0;
    margin:0;
  }
}

.firma-wrapper{
  padding: 20px;
  text-align:right;
  max-width: 600px;
  float:right;
  h1,h2,h3{
    text-align:right;
  }

  .firma-link{
    font-size:16px;
    display:inline-block;
    margin-left:30px;
  }
}


.container-title{
  position: relative;
  padding-bottom: 0.63em;
  margin-bottom: 20px;
  color:@brand-color;
  text-transform: uppercase;

  &:before{
    background-color: rgba(218,221,221,0.5);
    content: '';
    height: 1px;
    width:100%;
    bottom: 0;
    position: absolute;
    left: 0;
    right: auto;
    display: block;
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
  }

  &:after{
    background-color: @brand-color;
    content: '';
    height: 6px;
    width: 200px;
    bottom: -3px;
    position: absolute;
    left: 0;
    right: auto;
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
    display: block;
  }

}

.container-title.title-right{

}