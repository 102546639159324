//.bx-wrapper img{
//  display:block;
//  max-width: 100%;
//}
//
//.bx-wrapper div{
//  display:block;
//  max-width: 100%;
//}

.homepage-slider{
  .property-slider-wrapper{
    height: 500px;
  }
  .property-slider-item{
    min-height: 500px;
  }

  padding:0;

}

@media(max-width: @screen-lg) {
  .homepage-slider{
    margin-top:0;
  }
}


.properties-slider-wrapper{
  height: 800px;
  overflow:hidden;
}


.property-slider-item{
  min-height: 800px;
  background-color: #464646;
  position:relative;

  .property-slider-info{

    padding: 20px;
    color: #fff;
   margin-top: 130px;
    left:0;



    .property-slider-leftinfo{
      max-width: 500px;
    }

    .property-slider-delim{
      margin:20px 0;
      height:1px;
      border-bottom: 1px solid rgba(255,255,255,.5);
      clear:both;
    }

    .property-slider-infoinner{
      background: rgba(225, 225, 225, 0.8);
      padding: 10px;
      float:left;
      color: #464646;
    }

    .property-slider-more{
      border:1px solid #bdbdbd;
      margin-top: 15px;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      padding: 10px 30px 10px 12px !important;
      color: #868686;
      border-radius: 5px;
      text-transform: uppercase;
     //background-color: #f6f6f6;
      background: -webkit-linear-gradient(#f8f8f8, #f2f2f2); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(#f8f8f8, #f2f2f2); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(#f8f8f8, #f2f2f2); /* For Firefox 3.6 to 15 */
      background: linear-gradient(#ffffff, #f2f2f2); /* Standard syntax */
      -webkit-transition: color 0.2s linear;
      -moz-transition: color 0.2s linear;
      -ms-transition: color 0.2s linear;
      -o-transition: color 0.2s linear;
      transition: color 0.2s linear;

    &:hover{
      color: #393939;
      text-decoration: none;
      background: linear-gradient(#f2f2f2, #ffffff);
    }
      span{
        display: inline-block;
        padding: 0 10px;
      }
    }

    h3{
      font-size: 24px;
      color: #464646;
      text-decoration: none;
      margin-top:0;
      background: rgba(225, 225, 225, 0.8);
      padding: 5px 10px 0px 10px;
      line-height: 38px;
      //  overflow: hidden;
      //  text-overflow: ellipsis;
      //  white-space: nowrap;
      margin-bottom: 10px;
      width: 100%;
      a{
        color: #464646;
        text-decoration: none;
      }
    }

    .property-box-meta-citem{
      color: #fff;
      i{
        color: #fff;
      }
    }

    .property-slider-price{
      font-size: 34px;
      float:right;
      background: rgba(225, 225, 225, 0.8);
      font-weight: 500;
      color: #464646;
      padding:10px;
    }

    .property-slider-view{
      color: #fff;
      float: right;
    }
  }
}

@media(max-width: @screen-lg)
{
  .properties-slider-wrapper,.property-slider-item  {
    min-height: 650px;
  }
}

@media(max-width: @screen-md)
{
  .properties-slider-wrapper,.property-slider-item  {
    min-height: 550px;
  }
}

@media(max-width: @screen-sm)
{
  .properties-slider-wrapper,.property-slider-item  {
    min-height: 350px;
  }
}