
.search-form-vertical{
  background: #fff;
  padding: 15px;

}

.search-form-wrapper{
  margin-bottom: 30px;



  .search-inner {
    padding: 30px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 1px solid #c9c9c9;
    border-top: none;
    position:relative;

    box-shadow: inset 0 -1px 1px white;
    background-color: #f2f2f2;
    background: -webkit-linear-gradient(#f8f8f8, #f2f2f2);
    background: -o-linear-gradient(#f8f8f8, #f2f2f2);
    background: -moz-linear-gradient(#f8f8f8, #f2f2f2);
    background: linear-gradient(#f8f8f8, #ebebeb);

  }

  .search-inner{
    border-top:35px solid @brand-color;
  }

  .btn.btn-primary{
    font-size: 13px;
    letter-spacing: 0.2px;
   // padding: 10px 35px 10px 35px !important;
    color: white;
    border-radius: 5px;
    border: 1px solid #69992c;
    box-shadow: inset 0 1px 1px white;
    text-shadow: 0px -1px 3px #0e4562;
    background-color: #81be32;
    background: -webkit-linear-gradient(#81be32, #76ae2e);
    background: -o-linear-gradient(#81be32, #76ae2e);
    background: -moz-linear-gradient(#81be32, #76ae2e);
    background: linear-gradient(#81be32, #76ae2e);
    text-transform: uppercase;
    &:hover{
      color: white;
      text-decoration: none;
      background: -webkit-linear-gradient(#81be32, #8fc34c);
      background: -o-linear-gradient(#81be32, #8fc34c);
      background: -moz-linear-gradient(#81be32, #8fc34c);
      background: linear-gradient(#81be32, #8fc34c);
    }
  }


}

.homepage-search{
  margin-top:-80px;


}
