.splash-main-title{
  float: right;
  margin-top: 500px;
  width: 400px;
  text-align: center;
  position: relative;
  h1{
    display: block;
    width: 100%;
    background: rgba(30,24,26,.8);
    font-size: 57px;
    font-weight: 100;
    margin-bottom:0;
  }
  h2{
    margin-top:0;
    background: rgba(253,198,0,.8);
    font-size: 30px;
    font-weight: 300;
    color: #000 !important;
  }

}

.splash-main-info{
  margin-top: 50px;
  clear:both;
  float:right;
  width: 80%;
  padding: 30px;
  background: rgba(30,24,26,.8);

  color: #fff;
  font-size: 16px;
  line-height: 160%;
}

@media(max-width: @screen-xs-min){
  .splash-main-title{
    width: 100%;
    margin-top:300px;
    h1{
      font-size: 45px;
    }
    h2{
      font-size: 20px;
    }
  }

  .splash-main-info{
    width: 100%;
  }
}

.about-me-splash{
  min-height: 500px;
  .half-splash-image{
    background-position: center top;
  }
}



.template-line{
  display: block;
  height: 40px;
  background: #FFC926;
}

.quote{

  margin-top:100px;
  font-size: 30px;
  font-weight: 200;
  line-height: 140%;

  .quote-author{
    display: block;
    float: right;
    font-size: 20px;
    font-weight: 300;
  }
}

.splash-contact{
  padding: 50px 0 50px 0 !important;
  margin-bottom:0;
  color: #fff;

  .form-response{
    color: #fff;
  }
}

.splash-contact .contact-form-message{
  height: 200px;
}

.btn-success{
  background: @brand-color;
  border-color:  @brand-color;

}

.btn-success:hover, .btn-success:active, .btn-success:visited{
  background: lighten( @brand-color,10%) !important;
  border-color: lighten( @brand-color,10%) !important;
}

#bump{
  display:block;
}

.property-main-gallery-item.item-video{
  height: 540px;
}

.ppicker-box{
  text-align:center;
  height: 280px;
  h2{
    text-align:center;
    color: #fff;
    margin-top: 100px;
  }
  .ppicker-button{
    display: inline-block;
    margin-right: 10px;
    padding: 10px 20px;
    background: #383F44;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    &:hover{
      background: fade( #383F44, 70%);
      text-decoration: none;
    }
  }
}

.propertiesareabox{
  color: #fff;
  ul{
    list-style:none;
    margin:0;
    li{
      list-style:none;
      padding:0;
      margin:0;

      a{

      }
    }
  }
}
