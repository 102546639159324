
.header-band{
//  background: #EDF1F2;
  padding: 20px 0 20px;
  position:relative;

  .header-band-image{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-size: cover;
    background-position: center center;
    //opacity: .2;
  }
}